import Mock from "../mock";

const database = {
  information: {
    name: 'Aaron Esposito',
    aboutContent: "I am a full-stack web developer, veteran and father with a passion for technology and innovation.",
    age: 31,
    phone: '',
    nationality: 'American',
    language: 'English',
    email: 'esposito.aaron93@gmail.com',
    address: '',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/aaronesposito/',
      dribbble: '',
      github: 'https://gitlab.com/esposito.aaron93'
    },
    brandImage: './images/brand-image.jpg',
    aboutImage: './images/about-image.jpg',
    aboutImageLg: './images/about-image-lg.jpg',
    cvfile: './files/resume.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Wellness Whale",
      subtitle: "A wellness tracking and journaling application using FastAPI and SQL for backend data management and React-Redux for frontend with Tailwind styling.",
      imageUrl: "./images/wellness-whale-portfolio.jpg",
      largeImageUrl: ["./images/wellness-whale-portfolio-lg.jpg",
      "./images/wellness-whale-portfolio-lg2.jpg",
      "./images/wellness-whale-portfolio-lg3.jpg",
      "./images/wellness-whale-portfolio-lg4.jpg"],
      url: 'https://gitlab.com/beluga-buddies/wellness-whale'
    },
    {
      id: 2,
      title: "CarCar",
      subtitle: "A car dealership tracking application. Provides a method for showing current stock, tracking vehicle sales, and tracking and scheduling vehicle service appointments. CarCar is a boilerplate study of React using Django for backend API microservices",
      imageUrl: "./images/carcar-portfolio.jpg",
      largeImageUrl: [
        "./images/carcar-portfolio-lg.jpg",
        "./images/carcar-portfolio-lg2.jpg",
        "./images/carcar-portfolio-lg3.jpg",
        "./images/carcar-portfolio-lg4.jpg",
        "./images/carcar-portfolio-lg5.jpg",
      ],
      url: 'https://gitlab.com/esposito.aaron93/carcar'
    },
    {
      id: 3,
      title: "8-bit Aces",
      subtitle: "8-bit aces is a top down WW2 aviation game developed fully in Python. It is an exploration of Object Oriented Programming. It is currently in a playable state but in early production",
      imageUrl: "./images/aces-portfolio.jpg",
      largeImageUrl: [
        "./images/aces-portfolio-lg.jpg",
        "./images/aces-portfolio-lg2.jpg",
        "./images/aces-portfolio-lg3.jpg",
      ],
      url: 'https://gitlab.com/esposito.aaron93/8-bit-aces'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2024 - Present",
        position: "Data Annotator - Software Engineering",
        company: "Data Annotation",
        details: "Crafted and analyzed software engineering prompts and responses to enhance LLM capabilities, improving operational efficiency and response quality."
      },
      {
        id: 2,
        year: "2022 - 2023",
        position: "IT Service Support Technician II",
        company: "Burlington Coat Factory",
        details: "Performed mobile device support for the integration of Zebra ET-51 tablets across 1000 Burlington Stores locations leading to a 90% daily usage rate by in store management. Resolved on average 20 customer support incidents per day with a focus on first call resolution resulting in a 20% increase in helpdesk trouble ticket resolutions",
      },
      {
        id: 3,
        year: "2021",
        position: "Consultation Agent",
        company: "Best Buy",
        details: "Performed Initial troubleshooting and triage of a broad range of customer IT problems, correcting the issue on the spot or offering follow-on resolution options. Recorded over 100 service orders using CRM system to maintain physical & digital records for customer products. Increased member retention by 10% & grew overall book of business through multiple product offerings such as Geek Squad Total Care."
      },
      {
        id: 4,
        year: "2011 - 2020",
        position: "Information Systems Technician",
        company: "US Navy",
        details: "Starting in 2011 I began my career as a junior IT specialist, providing helpdesk support in a high operational tempo environment while managing IT assets. Demonstrating a quick grasp of technical and managerial responsibilities, I was swiftly promoted to Assistant Manager of the IT department at my subsequent command. In this role, I expanded my expertise to include IT finance and acquisition, further enhancing department efficiency and resource allocation. This position allowed me to develop a comprehensive skill set in IT management and operations. "
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2023 - 2024",
        graduation: "Full Stack Software Engineering",
        university: "Hack Reactor",
        details: "Learned to employ multiple front end, back end, and database technologies to create fully functional full stack operations based on real world applications."
      },
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: [''],
    emailAddress: ['esposito.aaron93@gmail.com'],
    address: ""
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
