import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";

function About() {
  const [information, setInformation] = useState("");

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    axios.get("/api/services").then((response) => {
      setServices(response.data);
    });
    axios.get("/api/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Chester React Personal Portfolio Template About Page"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="About Me" />
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="mi-about-content">
                  <h3>
                    I am <span className="color-theme">{information.name}</span>
                  </h3>
                  <p>
                  I'm a full-stack developer with a deep-seated passion for technology and innovation. 
                  My tech journey began in the US Navy, serving as an Information Systems Technician for nine years. 
                  During my service, I mastered a range of IT skills from desktop troubleshooting to network support, 
                  laying a strong foundation in both technical proficiency and disciplined problem-solving.
                  </p>
                  <p>
                  After transitioning from military to civilian life, I continued in tech support roles for Best Buy's Geek Squad 
                  and Burlington Coat Factory's IT helpdesk. These experiences enriched my customer service skills and prepared 
                  me for my current role as a full stack developer. In this capacity, I employ a diverse tech stack that includes Python, 
                  JavaScript, React, Django, FastAPI, and PostgreSQL, crafting robust, scalable applications.
                  </p>
                  <p>
                  As a developer, I merge my extensive IT background with cutting-edge software development techniques to create 
                  impactful solutions. I am constantly updating my skills and exploring new technologies to stay at the forefront 
                  of the industry. I look forward to leveraging my experience in future projects and collaborations that drive real-world impact.
                  </p>
                  <a href={information.cvfile} className="mi-button">
                    Download Resume
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default About;
